import { Link } from '@remix-run/react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { LucideBuilding, LucideExternalLink, LucideImage } from 'lucide-react'
import React, { useMemo } from 'react'
import { Image, ListingPreviewBrokers } from '~/components'
import { Badge, Button, Checkbox } from '~/components/ui'
import { type Company, type Listing } from '~/models'
import {
  STATUSES,
  TYPES,
  cn,
  fmtAddress,
  fmtNumberAbbr,
  getPropertyTypeClassName,
} from '~/utils'

function SelectionCheckbox({
  checked,
  onCheckChanged,
}: {
  checked: boolean
  onCheckChanged: (value: boolean) => void
}) {
  // const [checkedListings, setCheckedListings] = useAtom(aCheckedListings)

  return (
    <Checkbox
      color=""
      className="absolute left-2 top-2 z-40 h-8 w-8 border-background bg-background text-accent-foreground ring-primary ring-offset-transparent hover:outline-none hover:ring-1 hover:ring-offset-1 data-[state=checked]:border-primary"
      // checked={checkedListings.includes(listing._id)}
      checked={checked}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onCheckChanged(!checked)
        // if (checkedListings.includes(listing._id)) {
        //   setCheckedListings(checkedListings.filter((id) => id !== listing._id))
        // } else {
        //   setCheckedListings([...checkedListings, listing._id])
        // }
      }}
    />
  )
}

function CardBadges({ listing }: { listing: Listing }) {
  if (!listing.filters) {
    return null
  }
  let values: (string | React.ReactNode)[] = []
  const filters = listing.filters!

  if (filters.price) {
    values.push(`$${fmtNumberAbbr(filters.price)}`)
  }
  if (
    filters.price &&
    filters.minSf &&
    filters.maxSf &&
    filters.minSf === filters.maxSf
  ) {
    values.push(`$${fmtNumberAbbr(filters.price / filters.minSf)}/SF`)
  } else if (
    filters.price &&
    filters.minAcres &&
    filters.maxAcres &&
    filters.minAcres === filters.maxAcres
  ) {
    values.push(`$${fmtNumberAbbr(filters.price / filters.minAcres)}/ac`)
  }
  if (filters.capRate) {
    values.push(`${filters.capRate}%`)
  }

  if (listing.type.toLowerCase().includes('lease')) {
    if (listing.status != 'leased') {
      if (filters.rateLabelAbbr) {
        values.push(filters.rateLabelAbbr)
      } else if (
        filters.minRate &&
        filters.maxRate &&
        filters.minRate !== filters.maxRate
      ) {
        values.push(`$${filters.minRate} - $${filters.maxRate} SF/year`)
      } else if (filters.minRate) {
        values.push(`$${filters.minRate} SF/year`)
      }
      if (
        filters.minSf &&
        filters.maxSf &&
        fmtNumberAbbr(filters.minSf) !== fmtNumberAbbr(filters.maxSf)
      ) {
        values.push(
          `${fmtNumberAbbr(filters.minSf)} - ${fmtNumberAbbr(filters.maxSf)} SF`
        )
      } else if (filters.minSf) {
        values.push(`${fmtNumberAbbr(filters.minSf)} SF`)
      }
      if (filters.spaces && filters.spaces > 1) {
        values.push(`${filters.spaces} Spaces`)
      }
    } else {
      if (
        filters.minRateHistoric &&
        filters.maxRateHistoric &&
        filters.minRateHistoric !== filters.maxRateHistoric
      ) {
        values.push(
          `$${filters.minRateHistoric} - $${filters.maxRateHistoric} SF/year`
        )
      } else if (filters.minRateHistoric) {
        values.push(`$${filters.minRateHistoric} SF/year`)
      }
      if (
        filters.minSfHistoric &&
        filters.maxSfHistoric &&
        fmtNumberAbbr(filters.minSfHistoric) !==
          fmtNumberAbbr(filters.maxSfHistoric)
      ) {
        values.push(
          `${fmtNumberAbbr(filters.minSfHistoric)} - ${fmtNumberAbbr(filters.maxSfHistoric)} SF`
        )
      } else if (filters.minSfHistoric) {
        values.push(`${fmtNumberAbbr(filters.minSfHistoric)} SF`)
      }
      if (filters.spacesHistoric && filters.spacesHistoric > 1) {
        values.push(`${filters.spacesHistoric} Spaces`)
      }
    }
  }
  // if (listing.type === 'sale') {
  if (filters.buildingSf) {
    values.push(
      <span className="inline-flex items-center gap-1">
        <LucideBuilding className="h-3.5 w-3.5 text-muted-foreground" />
        {`${fmtNumberAbbr(filters.buildingSf)} SF`}
      </span>
    )
  }
  // }

  if (
    filters.minAcres &&
    filters.maxAcres &&
    fmtNumberAbbr(filters.minAcres) !== fmtNumberAbbr(filters.maxAcres)
  ) {
    values.push(
      `${fmtNumberAbbr(
        Math.round(filters.minAcres * 1000) / 1000
      )} - ${fmtNumberAbbr(Math.round(filters.maxAcres * 1000) / 1000)} ac`
    )
  } else if (filters.minAcres) {
    values.push(
      `${fmtNumberAbbr(Math.round(filters.minAcres * 1000) / 1000)} ac`
    )
  }

  return (
    <div className="mt-2 flex flex-wrap gap-1">
      <Badge
        className={clsx(
          'text-base',
          listing.type === 'sale' && 'bg-[#0284c7] hover:bg-[#0284c7]',
          listing.type === 'lease' && 'bg-[#c026d3] hover:bg-[#c026d3]',
          listing.type === 'saleLease' && 'bg-[#4f46e5] hover:bg-[#4f46e5]'
        )}>
        {listing.status === 'active'
          ? TYPES[listing.type]
          : STATUSES[listing.status]}
      </Badge>
      {listing.propertyTypes.map((value, index) => {
        return (
          <Badge
            key={index}
            className={clsx('text-base', getPropertyTypeClassName(value))}>
            <span>{value}</span>
          </Badge>
        )
      })}
      {/* <Badge variant="secondary">
        DOM:{' '}
        {
          intervalToDuration({
            start: new Date(listing.created),
            end: new Date(),
          }).days
        }
      </Badge> */}
      {values
        .filter((v) => v)
        .map((value, index) => {
          return (
            <Badge key={index} variant="secondary" className="text-base">
              {value}
            </Badge>
          )
        })}
    </div>
  )
}

function CardCompany({ listing }: { listing: Listing }) {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData(['active-companies']) as {
    companies: Company[] | undefined
  }
  const logo = useMemo(() => {
    return data?.companies?.find((c) => listing.company._id === c._id)?.meta
      ?.logo
  }, [listing, data])

  return (
    <div className="flex items-center gap-2 px-4 pb-4">
      {(listing.brokers || []).length > 0 && (
        <span>
          <ListingPreviewBrokers className="p-0" brokers={listing.brokers} />
        </span>
      )}
      <Link
        className="group flex items-center gap-2"
        to={`/companies/${listing.company.slug}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}>
        {logo && (
          <img
            className="h-10 w-10 object-contain"
            alt={`${listing.company.name} Logo`}
            src={logo.thumbUrl}
          />
        )}
        <span className="line-clamp-1 underline-offset-4 group-hover:underline">
          {listing.company.name}
        </span>
      </Link>
    </div>
  )
}

type ListingCardBaseProps = {
  className?: string
  listing: Listing
  link?: string
  showCompany?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  onClick?: React.MouseEventHandler
  checked?: boolean
  onCheckChanged?: (value: boolean) => void
  lazyLoadImage?: boolean
}

export default function ListingCardBase({
  className,
  listing,
  link,
  showCompany = true,
  onMouseOver,
  onMouseOut,
  onClick,
  checked,
  onCheckChanged,
  lazyLoadImage = false,
}: ListingCardBaseProps) {
  return (
    <div
      className={cn(
        'relative rounded-md bg-background shadow-md',
        onClick && 'cursor-pointer',
        className
      )}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}>
      <Button
        asChild
        variant="ghost"
        size="map-sm"
        className="absolute right-2 top-2 z-10 bg-white ring-primary ring-offset-transparent hover:bg-white hover:outline-none hover:ring-1 hover:ring-offset-1">
        <Link to={link ? link : `/listings/${listing.slug}`} target="_blank">
          <LucideExternalLink className="h-4 w-4" />
        </Link>
      </Button>
      {onCheckChanged && (
        <SelectionCheckbox
          checked={checked || false}
          onCheckChanged={onCheckChanged}
        />
      )}
      <div className={cn('flex h-full flex-col')} onClick={onClick}>
        <div className="relative aspect-video overflow-hidden rounded-t-md bg-muted">
          {(listing.images || []).length > 0 ? (
            <Image
              className="aspect-video w-full object-cover"
              src={listing.images![0].thumbUrl}
              lazy={lazyLoadImage}
            />
          ) : (
            <LucideImage className="absolute inset-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform text-foreground/75" />
          )}
        </div>
        <div className="flex grow flex-col items-start px-4 pb-4 pt-3">
          <h3 className="mb-1 line-clamp-2 text-lg font-medium">
            <Link
              to={link ? link : `/listings/${listing.slug}`}
              target="_blank"
              className="underline-offset-4 hover:underline"
              onClick={(e) => e.stopPropagation()}>
              {listing.name}
            </Link>
          </h3>
          <p className="whitespace-pre-line text-base font-light tracking-wider text-foreground">
            {fmtAddress(listing.property)}
          </p>
          <CardBadges listing={listing} />
        </div>
        {showCompany && <CardCompany listing={listing} />}
      </div>
    </div>
  )
}
